



















import { Component, Prop, Vue } from 'vue-property-decorator'
import SimpleButton             from '../SimpleButton.vue'

@Component({
  components: { SimpleButton }
})
class ButtonWithMessage extends Vue {
  @Prop({ default: '' }) readonly classForTextBeforeButton!: string
}

export default ButtonWithMessage
